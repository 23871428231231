<style scoped lang="sass">

.font-instruction
  font-size: 35px
  font-weight: $weight-light
  font-style: italic
  color: $subtitle-grey
</style>
<template lang="pug">
div
  .font-prompt.text-center(:class="foodColor")
    | WHAT’S THE RIGHT TEMPERATURE FOR SHIPPING {{ food }}?
  .font-instruction.text-center
    | Drag the slider to the right temperature
</template>
<script>
export default {
  props: {
    question: {
      type: Number,
      default: null,
    },
  },
  computed: {
    food() {
      if (this.question === 1) {
        return 'AN ORANGE';
      } else if (this.question === 2) {
        return 'FROZEN PORK';
      } else {
        return 'BEER';
      }
    },
    foodColor: function () {
      if (this.question === 1) {
        return 'orange-text';
      } else if (this.question === 2) {
        return 'pink-text';
      } else {
        return 'yellow-text';
      }
    },
  },
};
</script>