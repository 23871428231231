<style scoped lang="sass">
canvas
  height: 100%
  width: 100%
  z-index: 10
  pointer-events: none
</style>
<template lang="pug">
  canvas(ref="confetti")

</template>
<script>
const confetti = require('canvas-confetti');

export default {
  mounted() {
    var count = 500;
    var defaults = { origin: { y: 0.9 } };
    var myConfetti = confetti.create(this.$refs.confetti, {
      resize: true,
      useWorker: true,
    });
    const fire = (particleRatio, opts) => {
      myConfetti(Object.assign({}, defaults, opts, { 
        particleCount: Math.floor(count * particleRatio),
        ticks: 190,
        scalar: 2

      }));
    };

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, { spread: 60 });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  },
};
</script>