export const data = [
  {
    name: '',
    facts: [],
  },
  {
    name: 'Domestic',
    facts: [
      {
        title: [
          'CN continuously monitors your cargo giving you E2E visibility and peace of mind:'
        ],
        bullets: [
          'Change set point temperature remotely',
          'Real-time email alerts',
          'Real-time fuel consumption reporting',
          'GPS tracking position',
        ],
      },
      {
        title: [
          'CN’s CargoCool is one of Canada’s largest reefer fleets--1,200 containers!', 
          'CN is the only intermodal service provider to offer refrigerated service across Canada, USA and Mexico'
        ],
        bullets: [],
      },
      {
        title: [
          'CN’s EcoTherm insulated containers keep products from freezing for up to 8 days so commodities like beer and pasta sauce don’t spoil!'
        ],
        bullets: [],
      },

    ],
  },
  {
    name: 'International',
    facts: [
      {
        title: [
          'CN continuously monitors your cargo giving you E2E visibility and peace of mind:'
        ],
        bullets: [
          'Change set point temperature remotely',
          'Real-time email alerts',
          'Real-time fuel consumption reporting',
          'GPS tracking position',
        ],
      },
      {
        title: [
          'CN IntelliGEN™ Powerpack Generator is one of a kind:'
        ],
        bullets: [
          'Just 1 powers 17 refrigerated containers',
          'Monitors power quality in transit',
          'Low carbon emissions engines'
        ],
      },
      {
        title: [
          'CN offers IntelliGEN™ Powerpack Generator service to all three North American coasts through ports in Vancouver, Rupert, Mobile, Montreal, Halifax, Saint John and Philadelphia'
        ],
        bullets: [],
      },
    ]
  },
  {
    name: 'Both',
    facts: [
      {
        title: [
          'CN continuously monitors your cargo giving you E2E visibility and peace of mind:'
        ],
        bullets: [
          'Change set point temperature remotely',
          'Real-time email alerts',
          'Real-time fuel consumption reporting',
          'GPS tracking position',
        ],
      },
      {
        title: [
          'CN’s CargoCool is one of Canada’s largest reefer fleets--1,200 containers!', 
          'CN is the only intermodal service provider to offer refrigerated service across Canada, USA and Mexico',
          'CN IntelliGEN™ Powerpack Generator is one of a kind:'
        ],
        bullets: [
          'Just 1 powers 17 refrigerated containers',
          'Monitors power quality in transit',
          'Low carbon emissions engines'
        ],
      },
      {
        title: [
          'CN’s EcoTherm insulated containers keep products from freezing for up to 8 days so commodities like beer and pasta sauce don’t spoil!',
          'CN offers IntelliGEN™ Powerpack Generator service to all three North American coasts through ports in Vancouver, Rupert, Mobile, Montreal, Halifax, Saint John and Philadelphia'
        ],
        bullets: [],
      },
    ]
  }
];