import { data } from './data';

const state = {
  selectedUser: '',
  factoids: {},
};

const getters = {
  userSelected: (state) => {
    return state.selectedUser;
  },
  userFactoids: (state) => {
    return state.factoids;
  },
  getFactoids: (state) => {
    return state.factoids;
  },
};

const mutations = {
  selectUser: (state, payload) => {
    state.selectedUser = payload;
  },
  getFactoids: (state, payload) => {
    state.factoids = payload;
  },
  resetUser: (state) => {
    state.selectedUser = '';
    state.factoids = {};
  },
};

const actions = {
  selectUser: ({ commit }, payload) => {
    commit('selectUser', payload);
  },
  getFactoids: ({ commit }, payload) => {
    let getFactoids = data.find((user) => user.name === payload.user);
    commit('getFactoids', getFactoids.facts[payload.gameNum - 1]);
  },
  resetUser: ({ commit }) => {
    commit('resetUser');
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'UserStore',
};