<style scoped lang="sass">
.modal-wrapper
  position: fixed
  display: flex
  flex-direction: column
  align-items: center
  left: 0
  top: 0
  height: 100vh
  width: 100vw
  z-index: $z-modal
  background: rgba(33, 38, 43, 0.9)
  backdrop-filter: blur(10px)
.userIcons svg
  width: 207px
  height: auto
  margin: 145px 0 64px 0
.response-container
  padding: 0 86px 0 82px
  margin-bottom: 44px
  font-size: 40px
  color: white
.coverage-map
  width: 908px
  height: 691px
  background-image: url('/coverage_map.svg')
  background-repeat: no-repeat
  position: relative
  .small-logo
    width: 92px
    height: auto
    position: absolute
    left: 92px
    top: 167px
  .factoid-position-container
    position: absolute
    left: 91px
    top: 211px
    width: 700px
    color: $title-dark-gray
    .user-name-text
      font-size: 42px
    .factoid-text
      margin-top: 32px
      font-size: 27px
      font-weight: 600
      .factoid-title
        margin-bottom: 10px
      ul
        margin: 0
      li
        font-weight: $weight-light
        margin-bottom: 5px
.button-container
  display: flex
  flex-direction: row
  justify-content: center
  margin-top: 30px
  button
    height: 77px
    width: 255px
    border: 4px solid $red
    font-size: 40px
    font-weight: $weight-regular
  & :nth-child(1)
    background-color: $red
    color: white
  & :nth-child(2)
    background-color: white
    color: $red
    margin-left: 55px
</style>
<template lang="pug">
.modal-wrapper
  .userIcons
    DomesticUser(v-if="userSelected === 'Domestic'")
    InternationalUser(v-if="userSelected === 'International'")
    BothUser(v-if="userSelected === 'Domestic & International'")
  .text-center.response-container(v-if="answer !== 'HOT' || answer !== 'COLD'" )
    span(:class="textColor") PERFECT!
    |  YOU’VE MATCHED THE
    span(:class="textColor")  {{ answer }}
    |  TO THE RIGHT TEMPERATURE RANGE
    |  SO IT WILL ARRIVE IN PERFECT CONDITION!
  .coverage-map
    Logo.small-logo
    .factoid-position-container
      .user-name-text
        | {{ userSelected }}
      .factoid-text
        .factoid-title(v-for="title in factoidTitle")
          | {{ title }}
        ul
          li(v-for="fact in factoidArray")
            | {{ fact }}
  .button-container
    button(
      @click="$router.push('/ending');"
      @touch="$router.push('/ending');"
    )
      | Finished
    button(
      v-if="showNextButton === true"
      @click="$emit('nextQuestion')"
      @touch="$emit('nextQuestion')"
    )
        | Next
</template>
<script>
import DomesticUser from '@/assets/svgs/characters/domestic_user.svg';
import InternationalUser from '@/assets/svgs/characters/international_user.svg';
import BothUser from '@/assets/svgs/characters/both_user.svg';
import Logo from '@/assets/svgs/logos/logo_light.svg';
export default {
  components: {
    DomesticUser,
    InternationalUser,
    BothUser,
    Logo,
  },
  props: {
    factoidTitle: {
      type: Array,
      default: null,
    },
    factoidArray: {
      type: Array,
      default: null,
    },
    showNextButton: {
      type: Boolean,
      default: false,
    },
    answer: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    userType: {
      type: String,
      default: 'Both',
    },
  },
  computed: {
    userSelected: function () {
      if (this.userType === 'Both') {
        return 'Domestic & International';
      } else {
        return this.userType;
      }
    },
  },
};
</script>