<style scoped lang="sass">

</style>
<template lang="pug">
//- div
//-   | hello beer screen
</template>
<script>
export default {
  props: {
    tempChecked: {
      type: Number,
      default: 0,
    },
  },
  watch:{
    tempChecked(temp) {
      this.checkTemp(temp);
    },
  },
  methods: {
    checkTemp(temp) {
      if (temp > 25) {
        this.$emit('answer', 'HOT');
      } else if (temp < 21) {
        this.$emit('answer', 'COLD');
      } else {
        this.$emit('correctAnswer');
        this.$emit('answer', 'BEER');
      }
    },
  },
};
</script>