<style scoped lang="sass">

$slider-width-number: 407
$slider-width: #{$slider-width-number}px
// actual slider width
$slider-height: 46px
$background-slider: gray
$background-filled-slider: $red
$thumb-background: $red
$shadow-size: 0px
$fit-thumb-in-slider: 0

@function makelongshadow($color, $size)
  $val: 5px 0 0 $size $color

  @for $i from 6 through $slider-width-number
    $val: #{$val}, #{$i}px 0 0 $size #{$color}

  @return $val
.number-display-container
  width: 183px
  height: 75px
  border-radius: 206px
  color: $title-dark-gray
  border: 10px solid $gray-thermometer
  display: flex
  align-items: center
  justify-content: center
  font-size: 40px
.thermometer-page-wrapper
  height: 100%
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-around
.thermometer-wrapper
  height: 760px
  width: 427px
  position: relative
  flex-shrink: 0
.thermometer
  position: relative
  height: 726px
  width: auto
  pointer-events: none
  z-index: 3
.thermometer-background-patch
  position: absolute
  left: 177px
  top: 51px
  height: 50px
  width: 50px
  background-color: gray
input
  align-items: center
  transform: rotate(-90deg) translateY(138px) translateX(-297px)
  appearance: none
  background: none
  position: absolute
  z-index: 2
  top: -30px
  left: -137px
  // left: math.div(-$slider-width-number, 2) + px
  // top: math.div(-$slider-height, 2)
  height: $slider-height
  overflow: hidden
  width: $slider-width

  &::-webkit-slider-runnable-track
    background: $background-filled-slider
    height: $slider-height

  &::-webkit-slider-thumb
    appearance: none
    // styling of actual thumb and needs to match $slider-height
    height: 46px
    width: 60px
    background: $thumb-background
    box-shadow: makelongshadow($background-slider, $shadow-size)
    margin-top: $fit-thumb-in-slider
</style>
<template lang="pug">
.thermometer-page-wrapper
  .number-display-container
    | {{ fahrenheit }}°F
  .thermometer-wrapper
    .thermometer-background-patch
    OrangeThermometer.thermometer(v-if="gameNumber === 1")
    PorkThermometer.thermometer(v-if="gameNumber === 2")
    BeerThermometer.thermometer(v-if="gameNumber === 3")
    input(
      type="range"
      id="game-thermometer"
      name="thermometer"
      :min="gameMin"
      :max="gameMax"
      :step="1"
      v-model="value"
      @touchstart="$emit('resetAnswer')"
      @touchend="$emit('checkTemp', $event)"
      @mouseup="$emit('checkTemp', $event)"
      :disabled="disableInput"
    )
  .number-display-container
    | {{ celsius }}°C
</template>
<script>
import OrangeThermometer from '@/assets/svgs/thermometers/orange_thermometer.svg';
import PorkThermometer from '@/assets/svgs/thermometers/pork_thermometer.svg';
import BeerThermometer from '@/assets/svgs/thermometers/beer_thermometer.svg';
export default {
  components: {
    OrangeThermometer,
    PorkThermometer,
    BeerThermometer,
  },
  props: {
    gameNumber: {
      type: Number,
      default: 1,
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { value: -20 };
  },
  computed: {
    fahrenheit() {
      return Math.round(((this.celsius * (9/5)) + 32) * 10) / 10;
    },
    celsius() {
      return this.value;
    },
    gameMin() {
      if (this.gameNumber === 1) {
        return -20;
      } else if ( this.gameNumber === 2) {
        return -32;
      } else {
        return 7;
      }
    },
    gameMax() {
      if (this.gameNumber === 1) {
        return 43;
      } else if ( this.gameNumber === 2) {
        return 10;
      } else {
        return 28;
      }
    },
  },
  watch: {
    gameNumber: function (game) {
      if (game === 2) {
        this.value = -32;
      } else if (game === 3) {
        this.value = 7;
      }
    },
  },
};
</script>