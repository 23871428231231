<style scoped lang="sass">
.response
  color: $title-dark-gray
</style>
<template lang="pug">
.font-prompt.mt-70(v-if="answer")
  .response(v-if="answer === 'COLD' || answer === 'HOT'")
    span(:class="foodColor") TOO {{ answer }}!
    |  GUESS AGAIN!
</template>
<script>
export default {
  props: {
    answer: {
      type: String,
      default: '',
    },
    question: {
      type: Number,
      default: null,
    },

  },
  computed: {
    foodColor: function () {
      if (this.question === 1) {
        return 'orange-text';
      } else if (this.question === 2) {
        return 'pink-text';
      } else {
        return 'yellow-text';
      }
    },
  },
};
</script>