<style lang="sass">
@import ~frow/_variables
@import @/assets/sass/_colors.sass
@import @/assets/sass/_variables.sass
@import @/assets/sass/global.sass
@import ~frow


.container
  height: 100vh
  width: 100vw
  background-color: $sky-blue
  overflow: hidden

.exit-right
  width: 100px
  height: auto
  position: absolute
  bottom: 10px
  right: 10px

</style>

<template lang="pug">
  #app.container(:class="{'screen-background': this.$route.name !== 'Credits'}")
    router-view(
      :key="$route.fullPath")
    FrontLandscape.front-hill-style(v-if="this.$route.name !== 'Credits'")
    transition(name="fade-in")
      router-link(
        tag="div"
        to="/"
        v-if="this.$route.name !== 'Credits' && this.$route.name !== 'Home'"
      )
        Exit.exit-right
    div(
      v-if="this.$route.name === 'Home'"
      @click="refresh"
    )
      Exit.exit-right
</template>

<script>
import Exit from '@/assets/svgs/buttons/exit_button_simple.svg';
import FrontLandscape from '@/assets/svgs/backgrounds/landscape_fg.svg';


export default {
  name: 'App',
  components: {
    Exit,
    FrontLandscape,

  },
  methods: {
    refresh() {
      this.$router.go();
    },
  },
  metaInfo: { title: 'CN Rail Game' },
};
</script>