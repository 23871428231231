import Vue from 'vue';
import Router from 'vue-router';

import Home from './views/Home.vue';
import User from './views/UserSelect.vue';
import Game from './views/GameScreen.vue';
import EndingScreen from './views/EndingScreen.vue';
import Credits from './views/Credits.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/userselect',
      name: 'User',
      component: User,
    },
    {
      path: '/game',
      name: 'Game',
      component: Game,
    },
    {
      path: '/ending',
      name: 'Ending',
      component: EndingScreen,
    },
    {
      path: '/credits',
      name: 'Credits',
      component: Credits,
    },
  ],
});