<style scoped lang="sass">
.content
  position: relative
  z-index: 2
.start-text
  padding: 248px 133px 0 132px
.game-started
  padding: 294px 175px 0 186px
.button-container
  position: fixed
  top: 616px
  width: 100%
  display: flex
  justify-content: center
button
  background-color: transparent
  border: none

.start-buttons
  height: 160px
  width: auto
  filter: drop-shadow(0 0 25px rgba(0, 0, 0, 0.15))
  &:active
    filter: drop-shadow(0 0 25px #f2585f)
    transform: scale(0.9)
.train-x-start
  left: -6131px
</style>

<template lang="pug">
div
  .font-title.content.text-center.steel-blue-text.start-text(v-if="!gameStarted")
    | GUESS THE RIGHT TEMPERATURE AND WIN COOL PRIZES!
  .font-title.content.text-center.steel-blue-text.game-started(
    v-if="gameStarted"
    :class="{'fadeOut': startTransition}"
  )
    | AT CN,
    div EVERY DEGREE COUNTS
  .button-container
    button(
      @click="gameStarted = true"
      @touch="gameStarted = true"
      v-if="!gameStarted"
    )
      StartButton.start-buttons
    button(
      @click="playGame"
      @touch="playGame"
      :class="{'fadeOut': startTransition}"
      v-if="gameStarted"
    )
      LetsPlayButton.start-buttons
  div(:class="{'fadeOut': startTransition}")
    Train.train.train-x-start.movingTrain

</template>

<script>
import Train from '@/assets/svgs/trains/train_with_items.svg';
import StartButton from '@/assets/svgs/buttons/start_button.svg';
import LetsPlayButton from '@/assets/svgs/buttons/lets_play_button.svg';

export default {
  name: 'Home',
  components: {
    Train,
    StartButton,
    LetsPlayButton,
  },
  data() {
    return {
      startTransition: false,
      gameStarted: false,

    };
  },
  created() {
    this.$store.dispatch('resetUser');
  },
  methods: {
    playGame() {
      this.startTransition = true;

      setTimeout(() => {
        this.$router.push('/userselect');
      }, 2000);
    },
  },
};
</script>