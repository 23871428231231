<style scoped lang="sass">
.ending-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-top: 323px
  .title-text
    font-size: 61px
    color: $title-dark-gray
  .content-text
    font-size: 50px
    color: $subtitle-grey
    font-weight: 600
    margin: 20px 127px 0 138px

button
  background-color: transparent
  border: none

.exit-button
  width: 548px
  height: auto
  position: absolute
  filter: drop-shadow(0 0 25px rgba(0, 0, 0, 0.15))
  top: 734px
  left: 268px
.train-x-start
  left: -6131px
</style>
<template lang="pug">
.screen-background
  .ending-content
    .title-text
      | CONGRATULATIONS!

    .content-text.text-center
      | YOUR PERISHABLE GOODS HAVE BEEN TRANSPORTED WITH THE GREATEST CARE,
      |  GIVING YOU PEACE OF MIND
  button.exit-button(
    @click="exitGame"
    @touch="exitGame"
  )
    Exit
  Train.train.train-x-start(:class="{'movingTrain': trainMoving}")
  div(ref="confetti" v-if="confettiShowing")
  audio(ref="finishedSound")
    source(src="../assets/sounds/close_out_screen.mp3" type="audio/mpeg")
</template>
<script>
import Train from '@/assets/svgs/trains/train_with_items.svg';
import Confetti from '@/components/Confetti.vue';
import Exit from '@/assets/svgs/buttons/exit_button_final.svg';

const confetti = require('canvas-confetti');

export default {
  components: {
    Train,
    Confetti,
    Exit,
  },
  data() {
    return {
      trainMoving: false,
      confettiShowing: false,
      hasExited: false,
    };
  },
  mounted() {
    let finalSound = this.$refs.finishedSound;
    finalSound.volume = 0.15;
    finalSound.play();

    this.trainMoving = true;
    setTimeout(() => {
      this.confettiDisplay();
      this.confettiShowing = true;
    }, 500);
    setTimeout(() => {
      if (!this.hasExited) {
        this.$router.push('/credits');
      }
    }, 100000);
  },
  methods: {
    confettiDisplay() {
      var myConfetti = confetti.create(this.$refs.confetti, {
        resize: true,
        useWorker: true,
      });
      var end = Date.now() + (1 * 1000);

      (function frame() {
        myConfetti({
          particleCount: 15,
          angle: 50,
          spread: 70,
          scalar: 1.5,
          origin: { x: 0, y: 0.25 },
        });
        myConfetti({
          particleCount: 15,
          angle: 130,
          spread: 70,
          scalar: 1.5,
          origin: { x: 1, y: 0.25 },
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      }());
    },
    exitGame() {
      this.hasExited = true;
      this.$router.push('/credits');
    },
  },
};
</script>