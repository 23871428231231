<style scoped lang="sass">
.top-content
  height: 370px
  display: flex
  padding-top: 65px
  flex-direction: column
  align-items: center

.middle-content
  position: relative
  height: 915px

.lower-content
  height: 780px

.train-x-start
  left: -4981px
  transition: transform 3s


.hideItem [data-name="FI Orange"],
.hideItem [data-name="FI CP"],
.hideItem [data-name="FI Beer"]
  opacity: 0
  transition: opacity 0.7s
.change-item-enter,
.change-item-leave-to
  opacity: 0
.change-item-leave-active,
.change-item-enter-active
  transition: opacity 0.7s
.change-item-enter-to
  opacity: 1

.item-styling
  height: auto
  position: fixed
  z-index: 100

.game-item-orange-positioning
  bottom: 257px
  left: 310px
.game-item-orange
  width: 448px
.game-item-orange-cold
  width: 400px

.game-item-pork-positioning
  bottom: 257px
  left: 197px
.game-item-pork
  width: 640px

.game-item-beer-positioning
  bottom: 206px
  left: 430px
.game-item-beer-warm
  width: 276px
.game-item-beer-cold
  width: 577px

.confetti-placement
  position: absolute
  bottom: 362px
  left: 0
</style>
<template lang="pug">
div
  div(ref="fadeIn")
    .top-content
      div(v-if="showQuestion")
        FoodQuestionPrompt(:question="gameNumber")
      FoodResponsePrompt(:answer="answer" v-if="foodResponsePromptShowing" :question="gameNumber")
    .middle-content
      GameThermometer(
        v-if="showThermometer"
        @checkTemp="checkTemp($event.target.value)"
        :gameNumber="gameNumber"
        @resetAnswer="foodResponsePromptShowing = false"
        :disableInput="showConfetti"
      )
  .lower-content
    div(:class="{'hideItem': !itemState }")
      Train.train.train-x-start(:style="{transform: `translateX(${trainPosX}px)`}")
    Orange(
      v-if="gameNumber === 1"
      :tempChecked="tempChecked"
      @correctAnswer="changeQuestion"
      @answer="answer = $event, foodResponsePromptShowing = true"
    )
    transition(name="change-item")
      FrozenOrange.item-styling.game-item-orange-positioning.game-item-orange-cold.animate-pop-in(
        v-if="gameNumber === 1 && answer === 'COLD'"
      )
    transition(name="change-item")
      HotOrange.item-styling.game-item-orange-positioning.game-item-orange.animate-pop-in(
        v-if="gameNumber === 1 && answer === 'HOT'"
      )
    PorkChop(
      v-if="gameNumber === 2"
      :tempChecked="tempChecked"
      @correctAnswer="changeQuestion"
      @answer="answer = $event, foodResponsePromptShowing = true"
    )
    transition(name="change-item")
      FrozenPork.item-styling.game-item-pork-positioning.game-item-pork.animate-pop-in(
        v-if="gameNumber === 2 && answer === 'COLD'"
      )
    transition(name="change-item")
      HotPork.item-styling.game-item-pork-positioning.game-item-pork.animate-pop-in(
        v-if="gameNumber === 2 && answer === 'HOT'"
      )
    Beer(
      v-if="gameNumber === 3"
      :tempChecked="tempChecked"
      @correctAnswer="changeQuestion"
      @answer="answer = $event, foodResponsePromptShowing = true"
    )
    transition(name="change-item")
      FrozenBeer.item-styling.game-item-beer-positioning.game-item-beer-cold.animate-pop-in(
        v-if="gameNumber === 3 && answer === 'COLD'"
      )
    transition(name="change-item")
      HotBeer.item-styling.game-item-beer-positioning.game-item-beer-warm.animate-pop-in(
        v-if="gameNumber === 3 && answer === 'HOT'"
      )
    Confetti.confetti-placement(v-if="showConfetti")
    audio(ref="hotSound")
      source(src="../assets/sounds/hot_sound.mp3" type="audio/mpeg")
    audio(ref="coldSound")
      source(src="../assets/sounds/cold_sound.mp3" type="audio/mpeg")
    audio(ref="correctSound")
      source(src="../assets/sounds/correct_sound.mp3" type="audio/mpeg")
  transition(name="modal-fade")
    div(v-if="showFactoidModal")
      FactoidModal(
        @nextQuestion="nextQuestion"
        :factoidTitle="factoidTitle"
        :factoidArray="factoidArray"
        :showNextButton="showNextButton"
        :answer="answer"
        :textColor="foodColor"
        :userType="userType"
      )
</template>
<script>
import { mapGetters } from 'vuex';

import FoodQuestionPrompt from '@/components/FoodQuestionPrompt.vue';
import FoodResponsePrompt from '@/components/FoodResponsePrompt.vue';
import Confetti from '@/components/Confetti.vue';

import Orange from '@/components/OrangeScreen.vue';
import FrozenOrange from '@/assets/svgs/items/orange_frozen.svg';
import HotOrange from '@/assets/svgs/items/orange_hot.svg';


import Beer from '@/components/BeerScreen.vue';
import FrozenBeer from '@/assets/svgs/items/beer_frozen.svg';
import HotBeer from '@/assets/svgs/items/beer_hot.svg';


import PorkChop from '@/components/PorkChopScreen.vue';
import FrozenPork from '@/assets/svgs/items/chilledPork_frozen.svg';
import HotPork from '@/assets/svgs/items/chilledPork_hot.svg';


import Train from '@/assets/svgs/trains/train_with_items.svg';

import GameThermometer from '@/components/GameThermometer.vue';
import FactoidModal from '@/components/FactoidModal.vue';

export default {
  components: {
    Orange,
    FrozenOrange,
    HotOrange,
    Beer,
    FrozenBeer,
    HotBeer,
    PorkChop,
    FrozenPork,
    HotPork,
    GameThermometer,
    FactoidModal,
    FoodQuestionPrompt,
    FoodResponsePrompt,
    Confetti,
    Train,
  },
  data() {
    return {
      gameStarted: false,
      gameNumber: 1,
      userType: 'Both',
      trainPosX: 0,
      tempChecked: null,
      showThermometer: true,
      showFactoidModal: false,
      foodResponsePromptShowing: false,
      showNextButton: false,
      answer: '',
      showConfetti: false,
      factoidTitle: [],
      factoidArray: [],
    };
  },
  computed: {
    ...mapGetters([
      'userSelected',
      'getFactoids',
    ]),
    foodColor: function () {
      if (this.gameNumber === 1) {
        return 'orange-text';
      } else if (this.gameNumber === 2) {
        return 'pink-text';
      } else {
        return 'yellow-text';
      }
    },
    itemState: function () {
      if (this.answer === 'HOT' || this.answer === 'COLD') {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    answer: async function (sound) {
      if (sound === 'HOT') {
        let toWarm = this.$refs.hotSound;
        toWarm.volume = 0.15;
        await toWarm.play();
      } else if (sound === 'COLD') {
        await this.$refs.coldSound.play();
      } else if (sound !== '' && sound !== 'HOT' && sound !== 'COLD') {
        await this.$refs.correctSound.play();
      }
    },
  },
  created() {
    this.gameStarted = true;
    this.showQuestion = true;
  },
  mounted() {
    this.$refs.fadeIn.classList.add('fadeIn');

    setTimeout(() => {
      this.trainPosX += 1217;
    }, 10);
  },
  methods: {
    changeQuestion() {
      this.showConfetti = true;
      setTimeout(() => {
        this.showFactoid();
        this.showThermometer = false;
        this.showFactoidModal = true;
      }, 3000);
      if (this.gameNumber < 3) {
        this.showNextButton = true;
      }
    },
    nextQuestion() {
      this.showConfetti = false;
      this.showThermometer = true;
      this.showFactoidModal = false;
      this.showQuestion = true;
      this.showNextButton = false;
      this.factoidTitle = [];
      this.answer = '';
      this.factoidArray = [];
      this.trainPosX += 1228;
      this.$nextTick(() => {
        this.gameNumber++;
      });
    },
    showFactoid() {
      let user = this.userSelected || 'Both';
      this.$store.dispatch('getFactoids', { user: user, gameNum: this.gameNumber });
      this.userType = user;
      this.factoidTitle = this.getFactoids.title;
      this.factoidArray = this.getFactoids.bullets;
    },
    checkTemp(temp) {
      if (this.showQuestion) {
        this.showQuestion = false;
      }
      this.tempChecked = parseInt(temp);
    },
  },
};
</script>