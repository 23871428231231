<style scoped lang="sass">
.content-container
  margin-top: 300px
.question-font
  color: $title-dark-gray
.train-x-left
  left: -6131px

.trainEnter
  animation-name: train-enter
  animation-fill-mode: forwards
  animation-timing-function: ease-out
  animation-duration: 3s
  animation-iteration-count: 1
@keyframes train-enter
  from
    transform: translateX(0)
  to
    transform: translateX(1150px)
</style>
<template lang="pug">
div
  .content-container(ref="content")
    .text-center.font-prompt.question-font(:class="{'fadeIn': trainEnter}")
      | What type of shipper are you?
    PlayerSelect(:class="{'fadeIn': trainEnter}" @userSelected="playGame")
  Train.train.train-x-left(ref="train" v-bind:class="{ trainEnter: trainEnter }")
  FrontLandscape.front-hill-style

</template>
<script>
import { mapGetters } from 'vuex';

import PlayerSelect from '@/components/PlayerSelect.vue';
import Train from '@/assets/svgs/trains/train_with_items.svg';
import FrontLandscape from '@/assets/svgs/backgrounds/landscape_fg.svg';
export default {
  components: {
    PlayerSelect,
    Train,
    FrontLandscape,
  },
  data() {
    return { trainEnter: true };
  },
  computed: {
    ...mapGetters([
      'userSelected',
    ]),
  },
  methods: {
    playGame() {
      this.$refs.content.classList.add('fadeOut');
      setTimeout(() => {
        this.$router.push('/game');
      }, 500);
    },
  },
};
</script>