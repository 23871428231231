<style scoped lang="sass">
.characters
  color: $subtitle-grey
  font-size: 32px
.users
  width: 215px
  height: auto
  cursor: pointer

</style>
<template lang="pug">
.frow.justify-around.pt-100.characters
  .text-center
    | Domestic
    .pt-20
      DomesticUser.users(
        @touchstart="selectUser('Domestic')"
        @click="selectUser('Domestic')"
      )
  .text-center
    | International
    .pt-20
      InternationalUser.users(
        @touchstart="selectUser('International')"
        @click="selectUser('International')"
      )
  .text-center
    |Both
    .pt-20
      BothUser.users(
        @touchstart="selectUser('Both')"
        @click="selectUser('Both')"
      )
</template>
<script>
import DomesticUser from '@/assets/svgs/characters/domestic_user.svg';
import InternationalUser from '@/assets/svgs/characters/international_user.svg';
import BothUser from '@/assets/svgs/characters/both_user.svg';
export default {
  components: {
    DomesticUser,
    InternationalUser,
    BothUser,
  },
  methods: {
    selectUser(user) {
      this.$store.dispatch('selectUser', user);
      this.$emit('userSelected');
    },
  },
};
</script>