<style scoped lang="sass">
.credit-container
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: white
svg
  width: 734px
  height: auto
.url-font
  font-size: 147px
  font-weight: 500
  padding-top: 50px
</style>
<template lang="pug">
.credit-container
  div
    div
      Logo
    .url-font.text-center.red-highlight
      | cn.ca
</template>
<script>
import Logo from '@/assets/svgs/logos/logo_light.svg';
export default {
  components: { Logo },
  created() {
    setTimeout(() => {
      this.$router.push('/');
    }, 8000);
  },
};
</script>